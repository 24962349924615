import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
// @ts-ignore
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import React, { useState } from "react"

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
}

export const Image = ({
  imageId,
  className,
  width,
  height,
  alt,
  src,
  style,
  imgStyle,
  objectFit,
  objectPosition,
  loading,
}) => {
  const [loaded, setLoaded] = useState(false)
  let fluidProps

  if (imageId && !/gif/.test(imageId)) {
    fluidProps = getFluidGatsbyImage(
      imageId,
      {
        maxWidth: width || 2400,
        maxHeight: height || undefined,
      },
      sanityConfig
    )
  }

  return (
    <figure className={cx(className)}>
      {fluidProps && imageId && !/svg/.test(imageId) ? (
        <Img
          fluid={fluidProps}
          alt={alt}
          defaultFadeIn={600}
          style={style}
          imgStyle={imgStyle}
          objectFit={objectFit ? objectFit : "cover"}
          objectPosition={objectPosition}
          loading={loading}
        />
      ) : (
        <img
          alt={alt}
          src={src ? src : fluidProps && fluidProps.src}
          loading="lazy"
          className={cx("x", "fade-in-on-load", {
            "is-loaded": loaded,
          })}
          onLoad={() => {
            setLoaded(true)
          }}
          style={style}
        />
      )}
    </figure>
  )
}

export default Image
